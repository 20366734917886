import React, { useState, useEffect } from "react";
import {
    Products,
    Navbar,
    Cart,
    Checkout,
    Categories,
    Events,
    MainPage,
    SelectedEvent,
    Bootsrap,
} from "./components";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    Navigate,
} from "react-router-dom";

import "./index.css";
import Footer from "./components/Footer/Footer";
import RadioCart from "./components/Cart/RadioCart";
import Termos from "./components/Termos/Termos";
import Contacto from "./components/Contacto/Contacto";
import Privacidade from "./components/Privacidade/Privacidade";

const App = () => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [order, setOrder] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [cartItems, setCartItems] = useState({}); // this is cart.jsx is newCart
    const [cartItemsBlob, setCartItemsBlob] = useState({}); // this is cart.jsx is cartItems
    const [alreadyInCart, setAlreadyInCart] = useState([]);

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    const newOnAddToCart = (
        user_number,
        event_id,
        imprim,
        digital,
        quantity = 1,
        index,
        search_number,
        precoDigital,
        precoPoster,
        precoIndividualDigital,
        precoIndividualPoster
    ) => {
        //console.log("DA A DAR MESMO DEPOIS DE PASSAR POR 7 COMPONENETS!!!")
        //let newcart = structuredClone(cartItems);
        //if (!isEmpty(cartItems)) {

        //}

        const isIndividual = search_number > 0 ? false : true;
        let totalPrice = 0;

        if (digital && !isIndividual) totalPrice += precoDigital;
        else if (digital && isIndividual) totalPrice += precoIndividualDigital;

        if (imprim && !isIndividual) totalPrice += precoPoster;
        else if (imprim && isIndividual) totalPrice += precoIndividualPoster;

        setCartItems({
            [event_id]: {
                [user_number]: {
                    [index]: {
                        imprim: imprim,
                        digital: digital,
                        quantity: quantity,
                    },
                },
            },
            individual: isIndividual,
            precoDigital: precoDigital,
            precoPoster: precoPoster,
            precoIndividualDigital: precoIndividualDigital,
            precoIndividualPoster: precoIndividualPoster,
            totalPrice: totalPrice,
        });

        //console.log({
        //[event_id]: {
        //[user_number]: {
        //[index]: {
        //imprim: imprim,
        //digital: digital,
        //quantity: quantity,
        //},
        //},
        //},
        //individual: isIndividual,
        //precoDigital: precoDigital,
        //precoPoster: precoPoster,
        //precoIndividualDigital: precoIndividualDigital,
        //precoIndividualPoster: precoIndividualPoster,
        //totalPrice: totalPrice,
        //});
        setCartIndex(indexCart());

        return;
        let newcart = cartItems;

        if (
            typeof newcart[event_id] == "undefined" ||
            isEmpty(newcart[event_id])
        ) {
            newcart[event_id] = {};
        }
        if (
            typeof newcart[event_id][user_number] == "undefined" ||
            isEmpty(newcart[event_id][user_number])
        ) {
            let temp = {};
            temp[user_number] = {};
            newcart[event_id][user_number] = temp;
        }
        if (
            typeof newcart[event_id][user_number][index] == "undefined" ||
            isEmpty(newcart[event_id][user_number][index])
        ) {
            let temp = {};
            temp = {
                image_blob: "",
                digital: digital,
                imprim: imprim,
                quantity: quantity,
            };
            newcart[event_id][user_number][index] = temp;
        }

        setCartItems(newcart);
        setCartIndex(indexCart());

        //let newCartJson = {};

        //newCartJson[`${event_id}_${user_number}_${index}`] = {
        //imprim: imprim,
        //digital: digital,
        //quantity: quantity,
        //}
        //newcart.push(newCartJson);
        //setCartItems(newcart);
    };

    const newOnRemoveFromCart = (user_number) => {
        try {
            let oldCart = cartItems;
            delete oldCart.user_number;
            setCartItems(oldCart);
        } catch (error) {
            console.error(error);
        }
    };

    const newUpdateCartQty = (user_number, quantity) => {
        try {
            let oldCart = cartItems;
            oldCart[user_number].quantity = quantity;
            setCartItems(oldCart);
        } catch (error) {
            console.error(error);
        }
    };

    const newEmptyCart = () => {
        try {
            setCartItems({});
            setCartItemsBlob([]);
            setAlreadyInCart([]);
        } catch (error) {
            console.error(error);
        }
    };

    //useEffect(() => {
    //fetchProducts();
    //fetchCart();
    //}, []);

    const indexCart = () => {
        try {
            const event_id = Object.keys(cartItems)[0];
            const user_number = Object.keys(cartItems[event_id])[0];
            if (Object.keys(cartItems[event_id]).length > 0) return "!";
        } catch (e) {
            return 0;
        }

        return 0;
    };
    const [cartIndex, setCartIndex] = useState(indexCart());

    //console.log(cart);

    //<Route exact path="/" element={<Products products={products} onAddToCart={handleAddToCart}/>} />
    return (
        <Router>
            <div>
                <Navbar
                    totalItems={cart.total_items}
                    cart={cartItems}
                    cartIndex={cartIndex}
                />

                <Routes>
                    <Route exact path="/" element={<MainPage />} />
                    <Route
                        exact
                        path="/termos-e-condicoes"
                        element={<Termos />}
                    />
                    <Route
                        exact
                        path="/politica-de-privacidade"
                        element={<Privacidade />}
                    />
                    <Route exact path="/dados-empresa" element={<Contacto />} />
                    <Route
                        exact
                        path="/carrinho"
                        element={
                            <Cart
                                newCart={cartItems}
                                cartItems={cartItemsBlob}
                                newEmptyCart={newEmptyCart}
                                alreadyInCart={alreadyInCart}
                                setAlreadyInCart={setAlreadyInCart}
                                setCartItems={setCartItemsBlob}
                                newOnAddToCart={newOnAddToCart}
                                newOnRemoveFromCart={newOnRemoveFromCart}
                                newUpdateCartQty={newUpdateCartQty}
                                cart={cart}
                                //handleUpdateCartQty={handleUpdateCartQty}
                                //handleRemoveFromCart={handleRemoveFromCart}
                                //handleEmptyCart={handleEmptyCart}
                                setCartIndex={setCartIndex}
                                indexCart={indexCart}
                                setNewCart={setCartItems}
                            />
                        }
                    />

                    {
                        //<Route path="/categorias" element={<Categories />} />
                    }

                    <Route path="/events" element={<Events />} />
                    {
                        //<Route path="/mainpage" element={<MainPage />} />
                    }
                    <Route
                        path="/evento/:id"
                        element={
                            <SelectedEvent newOnAddToCart={newOnAddToCart} />
                        }
                    />

                    <Route
                        exact
                        path="/checkout"
                        element={
                            <Checkout
                                cart={cart}
                                order={order}
                                //onCaptureCheckout={handleCaptureCheckout}
                                error={errorMessage}
                            />
                        }
                    />

                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer cartIndex={cartIndex} cartItems={cartItems} />
            </div>
        </Router>
    );
};

//react context if too many props
function NotFound() {
    return (
        <Navigate to="/" />
        //<div>
        //<br/>
        //<br/>
        //<br/>
        //<h2>Nothing to see here!</h2>
        //<p>
        //<Link to="/">Go to the home page</Link>
        //</p>
        //</div>
    );
}

export default App;
