import React, { Component } from "react";
import VerticalCarousel from "./VerticalCarousel";
import { config } from "react-spring";

let slides = [
    {
        key: 1,
        content: <img src={"https://picsum.photos/200"} />,
    },
    {
        key: 2,
        content: <img src={"https://i.imgur.com/i1S8aCl.jpg"} />,
    },
    {
        key: 3,
        content: <img src={"https://i.imgur.com/Rf6TlQ5.jpg"} />,
    },
    {
        key: 4,
        content: <img src={"https://i.imgur.com/qTe73fV.jpg"} />,
    },
    {
        key: 5,
        content: <img src={"https://i.imgur.com/dVP8aOA.jpg"} />,
    },
    {
        key: 6,
        content: <img src={"https://i.imgur.com/WJDZBkR.jpg"} />,
    },
    {
        key: 7,
        content: "6",
    },
    {
        key: 8,
        content: "7",
    },
];

export default class Example extends Component {
    state = {
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: true,
        config: config.gentle,
    };

    // onChangeInput = e => {
    //   this.setState({
    //     [e.target.name]: parseInt(e.target.value, 10) || 0
    //   });
    // };

    render() {
        return (
            <div
                style={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100vw",
                    height: "100vh",
                    margin: "0 auto",
                    background: "#7FfFbF",
                }}
            >
                <VerticalCarousel
                    slides={slides}
                    offsetRadius={this.state.offsetRadius}
                    showNavigation={this.state.showNavigation}
                    animationConfig={this.state.config}
                />
            </div>
        );
    }
}
