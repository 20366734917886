import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  images: {
    width: '10%',
    
    height: "auto",
    
   transition: ".5s ease",
   // backfaceVisibility: "hidden",
   // backgroundColour: "blue",
   "&:hover": {
      backgroundColour: "#008CBA",
    opacity: 0.5,
    },
  },

  zoom: {
    height: "152px",
  }


}));