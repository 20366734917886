import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./styles.css";
import "./fake.css";

import b64toBlob from "../../helper";

import useStyles from "./styles";

import Products from "../../../Products/Products";
import { Typography, TextField, Button, Grid } from "@material-ui/core";

const SelectedEvent = ({ newOnAddToCart }) => {
    const [eventInfo, setEventInfo] = useState("");
    let params = useParams();
    let navigate = useNavigate();
    //console.log(params.id)
    const classes = useStyles();
    const [searchNumber, setSearchNumber] = useState(null);
    const [eventPrices, setEventPrices] = useState({});
    const [error, setError] = useState(5484919819);

    // props entre aspas

    //const { from } = location.state;
    //let eventImage, eventName, eventDate, eventId, eventLocal;

    useEffect(() => {
        const api = async () => {
            //try {
            //console.log("fodase");
            ////({ eventImage, eventName, eventId, eventDate, eventLocal } = location.state);
            //setEventInfo(state);
            //}
            //catch (e) {
            const response = await fetch(
                "https://pauloministro.com:8000/getinfo/?event_id=" + params.id
            );
            if (response.ok) {
                const data = await response.json();
                ///data["eventImage"] = URL.createObjectURL(b64toBlob(data.eventImage, "image/jpeg"));
                let img_url = URL.createObjectURL(
                    b64toBlob(data.eventImage, "image/jpeg")
                );
                data["eventImage"] = img_url;
                setEventInfo(data);
                setEventPrices({
                    precoDigital: Number(data.eventPrecoDigital),
                    precoPoster: Number(data.eventPrecoPoster),
                    precoIndividualDigital: Number(
                        data.eventPrecoIndividualDigital
                    ),
                    precoIndividualPoster: Number(
                        data.eventPrecoIndividualPoster
                    ),
                });
            } else {
                //if not event redirect home
                navigate("/");
            }
        };
        //({ eventImage, eventName, eventDate, eventLocal } = api());
        //}
        api();
        //return () => {
        //console.log(eventInfo)
        //URL.revokeObjectURL(eventInfo.eventImage);
        //}
    }, [params.id]);
    //UNMOUNT
    useEffect(() => () => URL.revokeObjectURL(eventInfo.eventImage), []);

    const handleClick = () => {
        const value = document.getElementById("search_input").value;
        //TODO VER NUMERICO AQUI
        //console.log(error);
        if (value) {
            setSearchNumber(value);
        } else {
            setSearchNumber(null);
        }
    };

    //useEffect(() => {
    //() => {
    //URL.revokeObjectURL(eventInfo.eventImage);
    //}

    //}, []);

    //const Name = location.state.name;
    //if (!Name) Name = "fetch";
    //if (!location.state) {
    //eventImage = "fetchtheshitoutofmyapi";
    //}
    //else{

    //eventImage = location.state.eventImage;
    //const eventName = "NOME FIXE";
    //const eventDate = "DATA FIXE";
    //eventId = location.state.ident;
    // const b64toBlob = location.state.b64toBlob;
    //}

    //console.log(eventInfo);
    //return (
    //<Products
    //newOnAddToCart={newOnAddToCart}
    //searchNumber={searchNumber}
    //setSearchNumber={setSearchNumber}
    //eventImage={eventInfo.eventImage}
    //eventName={eventInfo.eventName}
    //eventDate={eventInfo.eventDate}
    //eventId={eventInfo.eventId}
    ///>
    //);

    return (
        <>
            {eventInfo && (
                <>
                    <div className="containerdrip">
                        <div className="rowdrip">
                            <div className="position">
                                <button onClick={() => setSearchNumber(null)}>
                                    <img
                                        className="event-card"
                                        src={eventInfo.eventImage}
                                        alt=""
                                        style={{
                                            width: "auto",
                                            height: "200px",
                                        }}
                                    />
                                </button>

                                <Typography
                                    variant="h6"
                                    className="center-title"
                                >
                                    <strong>
                                        {eventInfo.eventName.replace(/_/g, " ")}
                                    </strong>
                                </Typography>
                                <p className="event-card-details text-3xl font-bold underline">
                                    {eventInfo.eventLocal},{" "}
                                    {eventInfo.eventDate.replace("_", " ")}
                                </p>
                            </div>
                            <div className="search">
                                <div className="startnumber">
                                    <div className="search-label">
                                        <span>Digite o seu número</span>
                                    </div>
                                    <div>
                                        <input
                                            id="search_input"
                                            type="text"
                                            placeholder="0000"
                                            autoComplete="off"
                                            style={{ fontSize: "72px" }}
                                        />
                                    </div>
                                    <div className="search-button">
                                        <button
                                            onClick={handleClick}
                                            className="search-button"
                                            variant="contained"
                                            style={{
                                                color: "white",
                                                backgroundColor: "#1788da",
                                                width: "100%",
                                                height: "50px",
                                            }}
                                        >
                                            Pesquisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="linha" />
                    {error != searchNumber ? (
                        <Typography variant="h4" align="center">
                            <strong>
                                {searchNumber === null
                                    ? `Todos os participantes`
                                    : searchNumber == 0
                                    ? "Participantes não identificados"
                                    : `Número ${searchNumber}`}
                            </strong>
                        </Typography>
                    ) : (
                        <div style={{ marginTop: "2%" }}>
                            <Typography
                                align="center"
                                variant="h6"
                                color="error"
                            >
                                <strong>
                                    O participante Nº. {searchNumber} não foi
                                    encontrado. <br />
                                    Procure nos participantes não identificados.
                                </strong>
                            </Typography>
                        </div>
                    )}
                    {error != searchNumber && (
                        <Products
                            newOnAddToCart={newOnAddToCart}
                            searchNumber={searchNumber}
                            setSearchNumber={setSearchNumber}
                            eventImage={eventInfo.eventImage}
                            eventName={eventInfo.eventName}
                            eventDate={eventInfo.eventDate}
                            eventId={eventInfo.eventId}
                            eventPrices={eventPrices}
                            setError={setError}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default SelectedEvent;
