import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Lock from "@material-ui/icons/Lock";
import ReplayIcon from "@material-ui/icons/Replay";
import CheckIcon from "@material-ui/icons/Check";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import Reclamacoes from "../../assets/images/livro-reclamacoes.png";
import ReclamacoesOn from "../../assets/images/livro-reclamacoes-on.png";

import "./styles.css";

import { Typography } from "@material-ui/core";

import mastercard from "../../assets/images/mastercard.svg";
import paypal from "../../assets/images/paypal.svg";
import visa from "../../assets/images/visa.svg";

import { useLocation } from "react-router-dom";

const Footer = ({ cartIndex, cartItems }) => {
    const { pathname } = useLocation();
    const UpperFooter = () => {
        return (
            <div className="padding-well fundo-cinza top-margin">
                <div className="container">
                    <div className="roww">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center texto_gris bot-margin">
                            <AccessTimeIcon fontSize="large" />
                            {/*
                            <p className="tamano_h4">
                                <b>Envios</b>
        </p> */}
                            <Typography variant="h5">
                                <strong>Envios</strong>
                            </Typography>
                            <p className="texto-icono-pie">
                                Recebe as fotos digitais instantaneamente
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center texto_gris bot-margin">
                            <Lock fontSize="large" />
                            <Typography variant="h5">
                                <strong>Pagamentos</strong>
                            </Typography>
                            <p className="texto-icono-pie">
                                Pagamento 100% Seguro Garantido
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center texto_gris bot-margin">
                            <ReplayIcon fontSize="large" />
                            <Typography variant="h5">
                                <strong>Reembolsos</strong>
                            </Typography>
                            <p className="texto-icono-pie">
                                Garantia de devolução do dinheiro se não ficares
                                satisfeito
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 text-center texto_gris">
                            <CheckIcon fontSize="large" />
                            <Typography variant="h5">
                                <strong>Garantia</strong>
                            </Typography>
                            <p className="texto-icono-pie">
                                As fotos que recebes são sem marca de água e com
                                alta resolução
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LowerFooter = () => {
        return (
            <footer className="black_background padding-well">
                <div className="container">
                    <div className="roww black-text">
                        <div className="informacao">
                            <Typography variant="h6" gutterBottom>
                                Informação
                            </Typography>
                            <a href="/termos-e-condicoes">Termos e Condições</a>
                            <a href="/politica-de-privacidade">
                                Política de Privacidade
                            </a>
                            <a href="/dados-empresa">Dados da Empresa</a>
                            <div className="livro-reclamacoes">
                                <a
                                    href="https://www.livroreclamacoes.pt/Inicio/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>Livro de Reclamações</span>
                                </a>
                            </div>
                        </div>
                        <div className="payments">
                            <Typography variant="h6" gutterBottom>
                                Métodos de Pagamento
                            </Typography>
                            <div className="payment-methods">
                                <img src={mastercard} alt="mastercard" />
                                <img src={paypal} alt="paypal" />
                                <img src={visa} alt="visa" />
                            </div>
                        </div>
                        <div className="contact">
                            <Typography variant="h6" gutterBottom>
                                Contacto
                            </Typography>
                            <div>
                                <EmailIcon /> pauloministro2007@gmail.com
                            </div>
                            <div>
                                <PhoneIcon className="phone-icon" /> +351 917
                                741 820
                            </div>
                            Segue nos:
                            <div className="socials">
                                <a href="">
                                    <InstagramIcon
                                        className="social-icon"
                                        fontSize="large"
                                    />
                                </a>

                                <a href="https://www.facebook.com/profile.php?id=100088818122892">
                                    <FacebookIcon
                                        className="social-icon"
                                        fontSize="large"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="copyright black-text">
                        <Typography variant="body2" gutterBottom>
                            <strong>
                                © 2024 Paulo Ministro. Todos os direitos
                                reservados.
                            </strong>
                        </Typography>
                    </div>
                </div>
            </footer>
        );
    };
    let condition = false;
    try {
        const event_id = Object.keys(cartItems)[0];
        condition =
            Object.keys(cartItems[event_id]) == 0 && pathname === "/carrinho";
    } catch (e) {
        if (pathname === "/carrinho") {
            condition = true;
        } else {
            condition = false;
        }
    }

    if (pathname === "/dados-empresa") {
        condition = true;
    }

    return (
        <div
            className={
                condition ? "footer-container" : "footer-container-absolute"
            }
        >
            <UpperFooter />
            <LowerFooter />{" "}
        </div>
    );
};

export default Footer;
