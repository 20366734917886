import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    title: {
        marginTop: "5%",
    },
    emptyButton: {
        //minWidth: "150px",
        //[theme.breakpoints.down("xs")]: {
        //marginBottom: "5px",
        //},
        //[theme.breakpoints.up("xs")]: {
        //marginRight: "20px",
        //},
    },
    checkoutButton: {
        margin: "0px",
        marginLeft: "10px",
        "@media (max-width: 760px)": {
            marginLeft: "0px",
            marginTop: "10px",
        },
    },
    link: {
        textDecoration: "none",
    },
    cardDetails: {
        display: "flex",
        marginTop: "10%",
        justifyContent: "space-between",
        "@media (max-width: 760px)": {
            //make the buttons go down when the screen is small
            flexDirection: "column",

            alignItems: "center",
        },
    },
    buttons: {
        display: "flex",
        "@media (max-width: 760px)": {
            marginTop: "10px",
            flexDirection: "column",
        },
    },
}));
