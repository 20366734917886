import { Typography } from "@material-ui/core";

import "./styles.css";

const Privacidade = () => {
    return (
        <div className="privacidade">
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Política de Privacidade</strong>
            </Typography>
            <Typography variant="h5">
                Quando visita o Website pauloministro.com, não recolhemos
                informação até chegar ao ponto da compra.
            </Typography>
            <Typography variant="h5">
                Cookies são ficheiros de dados colocados no seu dispositivo ou
                computador e frequentemente incluem um identificador anónimo
                único.
            </Typography>
            <Typography variant="h5">
                O unico cookie (CRSF Token) que usamos é usado por questão de
                segurança e não guarda qualquer informação pessoal.
            </Typography>
            <Typography variant="h5" className="compras">
                Quando realiza uma compra ou tenta realizar uma compra através
                do Website, recolhemos certa informação sua, incluindo o seu
                nome, endereço de faturação, endereço de expedição, informação
                de pagamento, contacto, endereço de email, endereço de IP e
                número de telefone. Isto chama-se Informação da Encomenda.
            </Typography>
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Como usamos a sua informação pessoal</strong>
            </Typography>
            <Typography variant="h5">
                Geralmente, usamos a Informação da Encomenda recolhida para
                cumprir qualquer encomenda realizada através do Website
                (incluindo o processamento da sua informação de pagamento,
                tratar da expedição e fornecer-lhe as faturas e/ou confirmações
                da encomenda).
            </Typography>
            <Typography variant="h5">
                Adicionalmente, usamos esta Informação da Encomenda para:
            </Typography>
            <ul className="lista-dados">
                <li>
                    <Typography variant="h5">Comunicar consigo;</Typography>{" "}
                </li>

                <li>
                    <Typography variant="h5">
                        Analisar as nossas encomendas em busca de potenciais
                        riscos ou fraudes
                    </Typography>
                </li>
                <li>
                    <Typography variant="h5">
                        Usamos a Informação de Dispositivo que recolhemos para
                        nos ajudar a eliminar potenciais riscos e fraudes
                        (especialmente, o seu endereço de IP) e, mais
                        frequentemente, para melhorar e otimizar o nosso
                        Website.
                    </Typography>
                </li>
            </ul>
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Os seus Direitos</strong>
            </Typography>

            <Typography variant="h5">
                Se for residente na Europa, tem o direito de aceder à informação
                pessoal que temos sobre si e de pedir que a sua informação
                pessoal seja corrigida, atualizada ou eliminada. Se desejar
                exercer este direito, por favor, contacte-nos.
            </Typography>
            <Typography variant="h5">
                Além disso, se for residente na Europa, realçamos que
                processamos a sua informação para cumprir contratos que possamos
                ter consigo (por exemplo, se realizar uma encomenda através do
                Site) ou para, de outra qualquer forma, perseguir os nossos
                interesses comerciais legítimos listados acima.
            </Typography>
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Retenção de Dados</strong>
            </Typography>

            <Typography variant="h5">
                Quando realizar uma encomenda através do Website, manteremos a
                sua Informação de Encomenda nos nossos registos, a não ser que
                nos peça para eliminar a informação.
            </Typography>

            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Menores</strong>
            </Typography>

            <Typography variant="h5">
                Este Website não é destinado para menores de idade.
            </Typography>

            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                <strong>Alterações</strong>
            </Typography>

            <Typography variant="h5">
                Podemos atualizar esta política de privacidade ocasionalmente de
                forma a refletir, por exemplo, mudanças nas nossas práticas ou
                devido a outros motivos operacionais, legais ou regulatórios.
                Caso tenha mais questões e/ou precise de mais informação, não
                hesite em contactar-nos pelo formulário de contacto, mail, ou
                telefone.
            </Typography>
        </div>
    );
};

export default Privacidade;
