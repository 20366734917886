import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    //root: {
    //flexGrow: 1,
    //},
    images: {
        width: "10%",

        height: "auto",

        transition: ".5s ease",
        // backfaceVisibility: "hidden",
        // backgroundColour: "blue",
        "&:hover": {
            backgroundColour: "#008CBA",
            opacity: 0.5,
        },
    },

    zoom: {
        height: "152px",
    },
    root: {
        maxWidth: "100%",
    },
    media: {
        // height: 0,
        // paddingTop: "56.25%", // 16:9
        paddingTop: "100%",
    },
    cardActions: {
        display: "flex",
        justifyContent: "center",
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between",
    },
    images: {
        width: "56.25%",
        pointerEvents: "none",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
        backgroundColour: "blue",
        "&:hover": {
            backgroundColour: "#008CBA",
            opacity: 0.5,
        },
    },

    button: {
        width: "100%",
        height: "100%",
    },
    loading: {
        display: "flex",
        justifyContent: "center",
    },
}));
