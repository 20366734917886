import React from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Badge,
    MenuItem,
    Menu,
    Typography,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../assets/images/output.jpg";
import useStyles from "./styles";

const Navbar = ({ totalItems, cart, cartIndex }) => {
    const classes = useStyles();
    const location = useLocation();

    //const index = () => {
    //try {
    //const event_id = Object.keys(cart)[0];
    //const user_number = Object.keys(cart[event_id])[0];
    //if (Object.keys(cart[event_id][user_number]).length > 0) return "!";
    //}
    //catch (e) {
    //return 0;
    //}

    //return 0;
    //}
    return (
        <>
            {
                //<AppBar className={classes.appBar} color="inherit">
            }
            <Toolbar className={classes.appBar}>
                <Typography
                    component={Link}
                    to="/"
                    variant="h6"
                    className={classes.title}
                    color="inherit"
                >
                    <img
                        src={Logo}
                        alt="Paulo Ministro"
                        className={classes.image}
                    />
                    Paulo Ministro
                </Typography>
                <div className={classes.grow} />
                {/*location.pathname === '/' && (
                 */}
                <div className={classes.button}>
                    <IconButton
                        component={Link}
                        to="/carrinho"
                        aria-label="Mostrar os items no carro"
                        color="inherit"
                    >
                        <Badge badgeContent={cartIndex} color="secondary">
                            <ShoppingCart />
                        </Badge>
                    </IconButton>
                </div>
            </Toolbar>
            {
                //</AppBar>
            }
        </>
    );
};

export default Navbar;
