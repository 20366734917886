import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';


const FormInput = ({ name, label}) => {
    const { control } = useFormContext();
    const isError = false;

    return (
        <Grid item xs={12} sm={6}>
            <Controller 
                control={control}
                name={name}
                render = {({ field }) => (
                    <TextField
                        fullWidth
                        label={label}
                        defaultValue=""
                        required
                        />
                )}
                // as={TextField}
                // label={label}
                // fullWidth
                // required={required}
                />
        </Grid>
    );
}

export default FormInput;
