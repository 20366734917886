import React, { useState, useRef, useEffect } from "react";
import {
    Grid,
    Box,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Typography,
    Button,
    CircularProgress,
} from "@material-ui/core";
//import { render } from "react-dom";
//import ImageViewer from "react-simple-image-viewer";

import LazyLoad from "react-lazyload";

import Product from "./Product/Product";

import useStyles from "./styles";

import b64toBlob from "../Events/helper";

import ProductNew from "./Product/ProductNew";

import ProductFinal from "./Product/ProductFinal";
import { useNavigate } from "react-router-dom";
import "./new_styles.css";

//import { Image } from 'semantic-ui-react';

//import LazyLoad from 'react-lazyload';
//import ModalImage from 'react-modal-image';

// const products = [
//     {id: 1, name: 'Product 1', description: 'Description 1', price: '$1.00', image: 'https://cdn.discordapp.com/attachments/626449728988774401/923748716895170651/unknown.png'},
//     {id: 2, name: 'Product 2', description: 'Description 2', price: '$2.00', image: 'https://cdn.discordapp.com/attachments/626449728988774401/923696251323686973/IMG_20211223_215827.jpg'},
// ]

//function importAll(r) {
//const totalImages = []
//let images = {};
//r.keys().map((item, index) => { totalImages.push(images[item.replace('./', '')] = r(item)); });

//return totalImages;
//}

const Products = ({
    newOnAddToCart,
    products,
    onAddToCart,
    eventName,
    eventDate,
    eventId,
    eventImage,
    searchNumber,
    setSearchNumber,
    eventPrices,
    setError,
}) => {
    const classes = useStyles();
    const [resizedImages, setResizedImages] = useState([]);
    const [fetchError, setFetchError] = useState("");
    const [imageUser, setImageUser] = useState({});
    const [iconZero, setIconZero] = useState({});
    const [loading, setLoading] = useState(false);
    const [imagesPath, setImagesPath] = useState({});
    const navigate = useNavigate();

    //const imageArray = products.map(product => product.image.url);

    //const [currentImage, setCurrentImage] = useState(0);
    //const [isViewerOpen, setIsViewerOpen] = useState(false);
    //const images = [
    //"http://placeimg.com/1920/1080/any",
    //"http://placeimg.com/1920/1080/nature",
    //"http://placeimg.com/1920/1080/people",
    //"http://placeimg.com/1920/1080/tech",
    //];

    //const openImageViewer = useCallback((index) => {
    //setCurrentImage(index);
    //setIsViewerOpen(true);
    //}, []);

    //const closeImageViewer = () => {
    //setCurrentImage(0);
    //setIsViewerOpen(false);
    //};

    useEffect(() => {
        //normal api call to get all images from a specific user
        const apiCall = async () => {
            let resized_images = [];
            const response = await fetch(
                "https://pauloministro.com:8000/getimageuser/?event_id=" +
                    eventId +
                    "&search_number=" +
                    searchNumber
            );
            const data = await response.json();
            if (data.error) {
                //console.log(data.error);
                setError(searchNumber);
                //setFetchError(data.error);
                return;
            }
            setError("");
            let image_array = data.resized_images;

            for (let image of image_array) {
                resized_images.push(
                    URL.createObjectURL(b64toBlob(image, "image/jpeg"))
                );
            }
            setResizedImages(resized_images);
            setLoading(false);
        };

        const getIconZero = async () => {
            const response = await fetch(
                "https://pauloministro.com:8000/getzerologo/?event_id=" +
                    eventId
            );
            const data = await response.json();

            let temp = {};
            for (let [user, logo] of Object.entries(data["resized_images"])) {
                temp[user] = URL.createObjectURL(b64toBlob(logo, "image/jpeg"));
            }
            setIconZero(temp);
            setLoading(false);
        };

        const getAllImagesByZero = async () => {
            let resized_images = [];

            //console.log(searchNumber);
            const response = await fetch(
                "https://pauloministro.com:8000/getimagezero/?event_id=" +
                    eventId +
                    "&search_number=" +
                    searchNumber
            );
            const data = await response.json();
            if (data.error) {
                //console.log(data.error);
                setError(searchNumber);
                //setFetchError(data.error);
                return;
            }
            setError("");
            let image_array = data.resized_images;
            for (let image of image_array) {
                resized_images.push(
                    URL.createObjectURL(b64toBlob(image, "image/jpeg"))
                );
            }
            setResizedImages(resized_images);
            setLoading(false);
        };

        //api call to get all images by user in the specific event
        const getAllImagesByUser = async () => {
            // let resized_images = {};
            // const response = await fetch(
            //     "https://pauloministro.com:8000/getallresizedimagesbyuser/?event_id=" +
            //         eventId
            // );
            // const data = await response.json();
            // for (let [user, image] of Object.entries(
            //     data["all_resized_images"]
            // )) {
            //     resized_images[user] = URL.createObjectURL(
            //         b64toBlob(image, "image/jpeg")
            //     );
            // }
            // setImageUser(resized_images);
            // setLoading(false);
            // -------------------------------

            const response = await fetch(
                "https://pauloministro.com:8000/getuserimagess/?event_id=" +
                    eventId
            );
            const data = await response.json();
            setImagesPath(data);
            setLoading(false);
        };
        if (searchNumber == 0) {
            setLoading(true);
            getIconZero();
        } else if (
            typeof searchNumber === "string" &&
            searchNumber.startsWith("0-")
        ) {
            setLoading(true);
            getAllImagesByZero();
        } else if (searchNumber !== null) {
            setLoading(true);
            apiCall();
        } else if (searchNumber === null) {
            setLoading(true);
            getAllImagesByUser();
        }
    }, [searchNumber, eventId]);
    //componentDidUnmount
    useEffect(
        () => () => {
            resizedImages.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
        },
        [resizedImages]
    );
    const handleNewCart = () => {
        newOnAddToCart(
            searchNumber,
            eventId,
            false,
            true,
            1,
            0,
            searchNumber,
            eventPrices.precoDigital,
            eventPrices.precoPoster,
            eventPrices.precoIndividualDigital,
            eventPrices.precoIndividualPoster
        );
        navigate("/carrinho");
    };
    //const grandes = importAll(require.context('../../fotos/', false, /\.(png|jpe?g|svg|thumbnail)$/));

    return (
        //<main className={classes.content}>
        //<div className={classes.toolbar} />
        //<Grid container justifyContent="center" rowSpacing={{ xs: 4}} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4}} >
        //{products.map((product, index) => (
        //<Grid item xs={12} sm={6} md={4} key={product.id}>
        //<Product product={product} onAddToCart={onAddToCart} image={Object.values(product.image)[1]} images={imageArray} Index={index} />
        //</Grid>
        //))}
        //</Grid>

        <main className={classes.content}>
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}
            <div className={classes.toolbar} />
            {searchNumber === null ? (
                //<div>
                //<p>{1}</p>
                //<img src={imageUser["2"]} alt="user1" />
                //</div>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="center" spacing={4}>
                        {Object.entries(imagesPath).map(
                            ([user_number, image], index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={user_number}
                                >
                                    <div style={{ maxWidth: "100%" }}>
                                        <Card className={classes.root}>
                                            <LazyLoad key={image}>
                                                <CardMedia
                                                    className={classes.media}
                                                    // image={image}
                                                    image={
                                                        "https://pauloministro.com:8000/media" +
                                                        // imagesPath[user_number]
                                                        image
                                                    }
                                                />
                                            </LazyLoad>
                                            <CardContent>
                                                <div
                                                    className={
                                                        classes.cardContent
                                                    }
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="h6"
                                                        component="h2"
                                                    >
                                                        {user_number == 0
                                                            ? "Participantes não identificados"
                                                            : "Número: " +
                                                              user_number}
                                                    </Typography>
                                                </div>
                                                {/* <Typography
                                                    style={{ align: "left" }}
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    Teste
                                                </Typography> */}
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                className={classes.cardActions}
                                            >
                                                <Button
                                                    style={{
                                                        color: "#1788da",
                                                    }}
                                                    onClick={() => {
                                                        // make it so that the page goes to the top
                                                        window.scrollTo(0, 0);

                                                        setSearchNumber(
                                                            user_number
                                                        );
                                                    }}
                                                >
                                                    Mostrar Fotos
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
            ) : searchNumber == 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="center" spacing={4}>
                        {Object.entries(iconZero).map(
                            ([user_number, image], index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={user_number}
                                >
                                    <div style={{ maxWidth: "100%" }}>
                                        <Card className={classes.root}>
                                            <CardMedia
                                                className={classes.media}
                                                image={image}
                                            />
                                            <CardContent>
                                                <div
                                                    className={
                                                        classes.cardContent
                                                    }
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="h6"
                                                        component="h2"
                                                    >
                                                        {"Participante não identificado Nº " +
                                                            (Number(
                                                                user_number
                                                            ) +
                                                                1)}
                                                    </Typography>
                                                </div>
                                                {/*  <Typography
                                                    style={{
                                                        align: "left",
                                                    }}
                                                    variant="body1"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    Teste
                                                </Typography> */}
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                className={classes.cardActions}
                                            >
                                                <Button
                                                    style={{
                                                        color: "#1788da",
                                                    }}
                                                    onClick={() =>
                                                        setSearchNumber(
                                                            "0-" + user_number
                                                        )
                                                    }
                                                >
                                                    Mostrar Fotos
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
            ) : (
                //<div>
                //<center>
                //<h1>{fetchError}</h1>
                //</center>
                //{setFetchError(null)}
                //</div>
                <>
                    <Grid
                        container
                        justifyContent="center"
                        rowspacing={{ xs: 4 }}
                        columnspacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                    >
                        {/*<ProductNew images={resizedImages} /> */}
                        {resizedImages.map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} key={product.id}>
                                <Product
                                    event_id={eventId}
                                    user_number={searchNumber}
                                    newOnAddToCart={newOnAddToCart}
                                    fetchError={fetchError}
                                    onAddToCart={onAddToCart}
                                    image={product}
                                    Index={index}
                                    resizedImages={resizedImages}
                                    eventPrices={eventPrices}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <center>
                        <hr className="linha_dois" />
                        <Button
                            onClick={handleNewCart}
                            style={{
                                marginTop: "80px",
                                backgroundColor: "#1788da",
                                color: "white",
                                width: "200px",
                            }}
                        >
                            <div
                                style={{
                                    position: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                Comprar pack
                                <p style={{ fontSize: "10px" }}>
                                    (Todos os ficheiros - 10€)
                                </p>
                            </div>
                        </Button>
                    </center>
                </>
            )}
        </main>

        //   <main className={classes.content}>
        //     <div className={classes.toolbar} />
        //     <Grid container justifyContent="center" rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={4}>

        //         <Image.Group size="small">
        //         {products &&
        //             products.map(product => (
        //                 <Grid item xs={12} sm={6} md={4}>
        //               <LazyLoad key={product.name} >
        //                 <ModalImage
        //                 small={product.image.url}
        //                 large={product.image.url}
        //                 alt={product.name}
        //                 hideDownload={true}
        //                 hideZoom={true}
        //                 className={classes.zoom}
        //                 />
        //             </LazyLoad>
        //           </Grid>
        //             ))}
        //         </Image.Group>
        //     </Grid>

        // </main>

        // onClick={() => openImageViewer(index)}

        // <main className={classes.content}>
        //     <div className={classes.toolbar, classes.image} />
        //         <Grid container justifyContent="center" rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={4}>
        //                 {images.map((src, index) => (
        //                     <img src={src} alt="autamae" className={classes.images} key={index} onClick={() => openImageViewer(index)} />
        //                     ))}

        //                 {isViewerOpen && (
        //                 <ImageViewer
        //                     src={images}
        //                     currentIndex={currentImage}
        //                     onClose={closeImageViewer}
        //                     disableScroll={false}
        //                     backgroundStyle={{
        //                         backgroundColor: "rgba(0,0,0,0.9)"
        //                     }}
        //                     closeOnClickOutside={true}
        //                 />

        //             )}
        //         </Grid>
        // </main>

        //             <div>
        //   {images.map((src, index) => (
        //     <img
        //       src={src}
        //       onClick={() => openImageViewer(index)}
        //     //   width="300"
        //       key={index}
        //     //   style={{ margin: "2px" }}
        //       alt=""
        //     />
        //   ))}

        //   {isViewerOpen && (
        //     <ImageViewer
        //       src={images}
        //       currentIndex={currentImage}
        //       onClose={closeImageViewer}
        //       disableScroll={false}
        //       backgroundStyle={{
        //         backgroundColor: "rgba(0,0,0,0.9)"
        //       }}
        //       closeOnClickOutside={true}
        //     />
        //   )}
        // </div>
    );
};

export default Products;
