import { useState } from "react";
import {
    Typography,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
} from "@material-ui/core";

import useStyles from "./styles";
import { Checkbox, Box, FormControlLabel } from "@material-ui/core";

//const NewCartItem = ({ img_index, event_id, user_number, image_number, image_data, onUpdateCartQty, onRemoveFromCart }) => {
const NewCartItem = ({
    image,
    image_index,
    digital,
    imprim,
    external,
    isPoster,
    price,
    updatePoster,
    selectedImage,
    handleCheckboxChange,
    checkboxStates,
    handleChange,
    selItem,
}) => {
    const classes = useStyles();
    const checkboxElement = checkboxStates[image_index];

    return (
        //<Card>
        //<CardMedia image={item.image.url} alt={item.name} className={classes.media}/>
        //<CardContent className={classes.CardContent} >
        //<Typography variant="h4" ><center>{item.name}</center></Typography>

        //</CardContent>
        //<CardActions className={classes.CardActions} >
        //<div className={classes.buttons}>
        //<Button size="small" type="button" onClick={() => onUpdateCartQty(item.id, item.quantity - 1)}>-</Button>
        //<Typography>{item.quantity}</Typography>
        //<Button size="small" type="button" onClick={() => onUpdateCartQty(item.id, item.quantity + 1)}>+</Button>
        //</div>
        //<Button className={classes.remove}variant="contained" color="secondary" type="button" onClick={() => onRemoveFromCart(item.id)}>Remover</Button>
        //<Typography variant="h5" className={classes.price} >{item.line_total.formatted_with_symbol}</Typography>

        //</CardActions>

        //</Card>
        //<Card>
        //<CardMedia image={image_data["image_blob"]} alt="Imagem" className={classes.media}/>
        //<CardContent className={classes.CardContent} >
        //<Typography variant="h4" ><center>OLA</center></Typography>
        //</CardContent>
        //<CardActions className={classes.CardActions} >
        //<div className={classes.buttons}>
        //{/* <Button size="small" type="button" onClick={() => onUpdateCartQty(item.id, item.quantity - 1)}>-</Button> */}
        //{ /*<Button size="small" type="button">-</Button>
        //<Typography>Quantidade</Typography>
        //{/* <Button size="small" type="button" onClick={() => onUpdateCartQty(item.id, item.quantity + 1)}>+</Button>
        //<Button size="small" type="button">+</Button> */}

        //<FormControlLabel
        //label="Digital"
        //control={<Checkbox checked={checked[0]} onChange={handleChangeDigital} />}
        ///>
        //<FormControlLabel
        //label="Poster"
        //control={<Checkbox checked={checked[0]} onChange={handleChangeDigital} />}
        ///>

        //</div>
        //{ /* <Button className={classes.remove}variant="contained" color="secondary" type="button" onClick={() => onRemoveFromCart(item.id)}>Remover</Button> */}
        //<Button className={classes.remove}variant="contained" color="secondary" type="button">Remover</Button>
        //<Typography variant="h5" className={classes.price} >PREÇO</Typography>

        //</CardActions>
        //</Card>

        <Card>
            <CardMedia
                image={image}
                alt={image_index}
                className={classes.media}
            />
            <CardContent className={classes.CardContent}>
                <Typography variant="h4">
                    <center>Foto Nº{image_index + 1}</center>
                </Typography>
            </CardContent>
            <CardContent>
                <FormControlLabel
                    label={`Formato digital (todas as fotos ${
                        price.individual
                            ? price.precoIndividualDigital
                            : price.precoDigital
                    }€)`}
                    control={<Checkbox checked={digital} disabled={true} />}
                />
                <FormControlLabel
                    label={`Poster 33x66cm (${
                        price.individual
                            ? price.precoIndividualPoster
                            : price.precoPoster
                    }€)`}
                    control={
                        <Checkbox
                            // if isPoster and the cart image is the selected image OR if isPoster and selectedImage != image_index and the cart image is the selected image
                            key={image_index}
                            checked={image_index === selItem}
                            onChange={() => handleChange(Number(image_index))}
                        />
                    }
                />
            </CardContent>
        </Card>
    );
    {
        {
            /*updatePoster(e.target.checked); */
        }
        /* control={<Checkbox checked={imprimArray[image_index][0]} onChange={handleChangePhysical} />}  */
    }
};

export default NewCartItem;
