
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  }, 
  images: {
    width: '56.25%',
    pointerEvents: 'none',
    transition: ".5s ease",
    backfaceVisibility: "hidden",
    backgroundColour: "blue",
    "&:hover": {
      backgroundColour: "#008CBA",
    opacity: 0.5,
    },
  },

  button: {
    width: '100%',
    height: '100%',
  },


  lightbox: {
    maxHeight: '100px%!important',
  },


}));