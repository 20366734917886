import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.css";
import Events from "../Events/Events";


import ministro1 from "../../assets/images/ministro1-resized.jpg";
import ministro2 from "../../assets/images/ministro2-resized.jpg";
import ministro3 from "../../assets/images/ministro3-resized.jpg";
import ministro4 from "../../assets/images/ministro4-resized.jpg";
import ministro5 from "../../assets/images/ministro5-resized.jpg";

export default class AutoPlay extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 4000,
            centerMode: false,
            draggable: false,
            cssEase: "linear",
            pauseOnHover: false,
            arrows: false,
        };
        return (
            <div className="main-page-container">
                { /*<div className="head-text"> */}
                    <div className="head-image">
                        <Slider {...settings}>
                            <div>
                                <img
                                    src={ministro1}
                                    alt="Foto do banner 1"
                                />
                            </div>
                            <div>
                                <img
                                    src={ministro2}
                                    alt="Foto do banner 2"
                                />
                            </div>
                      
                            <div>
                                <img
                                    src={ministro4}
                                    alt="Foto do banner 4"
                                />
                            </div>
                            <div>
                                <img
                                    src={ministro5}
                                    alt="Foto de banner 5"
                                />
                            </div>
                        </Slider>
                    </div>
                    {/*
                    <div className="text-on-image">
                        <h3 style={{ color: "red" }}>lorem ipsum</h3>
                </div>
        </div> */}

                <div className="social">
                    <div className="facebook">
                        <a
                            href="https://www.facebook.com/profile.php?id=100088818122892"
                            target="_blank"
                            rel="noopener roreferrer"
                        >
                            <img
                                src="https://www.sportograf.com/images/facebook.png"
                                alt="Facebook"
                                style={{ width: "auto", height: "30px" }}
                            />
                        </a>
                    </div>
                    <div className="instagram">
                        <a
                            href="https://www.instagram.com/pauloministro/?ref=badge"
                            target="_blank"
                            rel="noopener roreferrer"
                        >
                            <img
                                src="https://www.sportograf.com/images/instagram.png"
                                alt="Instagram"
                                style={{ width: "auto", height: "30px" }}
                            />
                        </a>
                    </div>
                </div>
                {/*drena louca de script que salva no drive sozinho hihii
                 */}
                <Events />
            </div>
        );
    }
}
