import React from "react";
import { Typography, Button, Divider } from "@material-ui/core";
import {
    Elements,
    CardElement,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Review from "./Review";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({
    checkoutToken,
    backStep,
    onCaptureCheckout,
    nextStep,
    shippingData,
    timeout,
}) => {
    const handleSubmit = async (event, elements, stripe) => {
        event.preventDefault();

        //if(!stripe || !elements) return;

        //const cardElement = elements.getElement(CardElement);

        //const { error, paymentMethod } = await stripe.createPaymentMethod({ type: "card", card: cardElement });

        //if(error) {
        //console.error("[error]", error);
        //} else {
        //console.log("merda")
        //console.log("merda")
        //console.log("merda")
        //console.log("merda")
        //console.log("merda")
        //console.log(shippingData)
        //console.log(checkoutToken.live.line_items)
        //const orderData = {
        //line_items: checkoutToken.live.line_items,
        //customer: { firstname: shippingData.firstname, lastname: shippingData.lastname, email: shippingData.email },
        //shipping: {
        //name: "Primary",
        //street: shippingData.address1,
        //town_city: shippingData.city,
        //county_state: shippingData.subdivision,
        //postal_zip_code: shippingData.zip,
        //country: shippingData.country
        //},
        //fullfillment: { shipping_method: shippingData.shippingOption },
        //payment: {
        //gateway: "stripe",
        //stripe: {
        //payment_method_id: paymentMethod.id
        //}
        //}
        //}

        //onCaptureCheckout(checkoutToken.id, orderData);
        //timeout();
        //nextStep();

        //}
    };

    return (
        <>
            {/*<Review checkoutToken={checkoutToken}/>*/}
            <Divider />
            <Typography variant="h6" gutterBottom style={{ margin: "20px 0" }}>
                Método do pagamento
            </Typography>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <form
                            onSubmit={(e) => handleSubmit(e, elements, stripe)}
                        >
                            <CardElement />
                            <br />
                            <br />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button variant="outlined" onClick={backStep}>
                                    Voltar
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!stripe}
                                    color="primary"
                                >
                                    Pague
                                </Button>
                            </div>
                        </form>
                    )}
                </ElementsConsumer>
            </Elements>
        </>
    );
};

export default PaymentForm;
