import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";

import useStyles from "./styles";

import Event from "./Event/Event";

import b64toBlob from "./helper";

function importAll(r) {
    const totalImages = [];
    let images = {};
    r.keys().map((item, index) => {
        totalImages.push((images[item.replace("./", "")] = r(item)));
    });

    return totalImages;
}

const Events = () => {
    const classes = useStyles();
    const [eventThumb, setEventThumb] = useState([]);
    const [numEvents, setNumEvents] = useState(0);
    const [eventInfo, setEventInfo] = useState([]);
    //const pattern = /\*/;
    //const grandes = importAll(require.context(`../../fotos/`, false, /\.(png|jpe?g|svg|thumbnail)$/));
    //const imagensEventos = api().then(data => setEventThumb(data));
    //console.log(imagensEventos);

    useEffect(() => {
        // can be optimized by putting this shit on the backend/server-side
        const api = async () => {
            //let eventsThumb = [];
            ////let response = fetch("https").then(response => response.json()).then(data => console.log(data));
            ////let response = await fetch("https/events/");
            ////let data = await response.blob();
            ////const imageUrl = URL.createObjectURL(data);
            ////setEventThumb(imageUrl);
            //let num = await fetch("https/events/?get_number=true");
            //let data = await num.json();
            //console.log(data.num_events);
            //setNumEvents(data.num_events);

            //for(let i = 0; i < data.num_events; i++){
            //console.log(i);

            //let response = await fetch("https/events/?number=" + i);
            //let img = await response.blob();
            //console.log(img);
            //let imageUrl = URL.createObjectURL(img);
            //eventsThumb.push(imageUrl);
            //}
            //console.log(eventsThumb);
            //setEventThumb(eventsThumb);

            // TODO can be optimized by mutating the image in the array and not creating a new one IMPORTANT
            const response = await fetch(
                "https://pauloministro.com:8000/events/"
            );
            const data = await response.json();
            const event_array = data.events;
            let eventsThumb = [];
            let eventInfo = [];
            for (let info of event_array) {
                eventInfo.push({
                    id: info.id,
                    name: info.name,
                    date: info.date,
                    local: info.local,
                    preco_digital: info.preco_digital,
                    preco_poster: info.preco_poster,
                    preco_individual_digital: info.preco_individual_digital,
                    preco_individual_poster: info.preco_individual_poster,
                });
                //eventInfo["id"] = info.id;
                //eventInfo["name"] = info.name;
                //eventInfo["date"] = info.date;
                eventsThumb.push(
                    URL.createObjectURL(b64toBlob(info.logo, "image/jpeg"))
                );
            }
            setEventThumb(eventsThumb);
            setEventInfo(eventInfo);
        };
        api();
    }, []);

    //componentDidUnmount
    useEffect(
        () => () => {
            eventThumb.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
        },
        []
    );

    return (
        <>
            {eventThumb.length > 0 && (
                <main className={classes.content}>
                    {/*<div className={classes.toolbar} />
                     */}
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container justifyContent="center" spacing={4}>
                            {eventInfo.map((product, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    key={index}
                                >
                                    <Event
                                        image={eventThumb[index]}
                                        images="ola"
                                        eventInfo={product}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </main>
            )}
        </>
    );
};

export default Events;
