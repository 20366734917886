import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./styles";
import CartItem from "./CartItem/CartItem";
import NewCartItem from "./CartItem/NewCartItem";

import b64toBlob from "../Events/helper";
import Delayed from "./Delayed";
import { object } from "prop-types";

import { EmptyCart } from "./EmptyCart";

const getDigitalAndPhysical = (cart) => {
    const event_id = Object.keys(cart)[0];
    const user_number = Object.keys(cart[event_id])[0];
    const img_index = Object.keys(cart[event_id][user_number])[0];
    const digital = cart[event_id][user_number][img_index]["digital"];
    const imprim = cart[event_id][user_number][img_index]["imprim"];
    //if (cart[event_id][user_number].length > 1) {
    //delete newCart[event_id][user_number][img_index];
    //}
    return [digital, imprim, img_index];
};
const EmptyNewCart = (
    cart,
    residedImages,
    setResizedImages,
    setCart,
    setCartIndex,
    CartIndex
) => {
    setCart({});
    setResizedImages([]);

    setCartIndex(0);
};

const initializeImagesArray = (residedImages, makeCondition) => {
    let temp_arr = [];
    for (let indexd = 0; indexd < residedImages.length; indexd++) {
        if (makeCondition(indexd)) {
            //setImprimArray(imprimArray, [true, false])
            //imprimArray.push([true, false])
            temp_arr.push([true, false]);
        } else {
            //setImprimArray(imprimArray, [false, true])
            //imprimArray.push([false, true])
            temp_arr.push([false, true]);
        }
    }
    return temp_arr;
    //setImprimArray(temp_arr)
    //console.log(imprimArray)
    //console.log(temp_arr)
};

const Cart = ({
    newCart,
    cart,
    alreadyInCart,
    setAlreadyInCart,
    handleUpdateCartQty,
    handleRemoveFromCart,
    handleEmptyCart,
    cartItems,
    setCartItems,
    newEmptyCart,
    cartIndex,
    setCartIndex,
    setNewCart,
}) => {
    // console.log(cart);
    // return <div>teste</div>
    const classes = useStyles();
    //const [cartItems, setCartItems] = useState({});
    const [teste, setTeste] = useState({});
    const [residedImages, setResizedImages] = useState([]);
    const [price, setPrice] = useState({ digital: 0, imprim: 0 });

    const buyDigital = "digital";
    const buyPoster = "poster";

    //    let digital = false;
    //let imprim = false;
    //let img_index = null;
    //try {
    //[digital, imprim] = getDigitalAndPhysical(newCart);
    //const event_idd = Object.keys(newCart)[0];
    //const user_numberd = Object.keys(newCart[event_idd])[0];
    //img_index = Object.keys(newCart[event_idd][user_numberd])[0];

    ////if (img_index){
    ////img_index = Object.keys(cart[event_id][user_number])[1];
    ////}
    //const event_id = Object.keys(newCart)[0];
    //const user_number = Object.keys(newCart[event_id])[0];
    //if (Object.keys(newCart[event_id][user_number]).length > 1) {
    //delete newCart[event_id][user_number][img_index];
    //}
    //} catch (e) {
    //<EmptyCart />;
    //}
    //let digital = Object.values(newCart)[0][0][0]["digital"];
    //let imprim =

    useEffect(() => {
        const apiCall = async () => {
            //await Promise.all(newCart.map(async (item, index) => {
            //const chaves = Object.keys(item);
            //if (alreadyInCart.includes(chaves[0])) {
            //return false
            //}

            //let [event_id, user_number, img_index] = chaves[0].split("_");

            //let resized_images = [];
            //const response = await fetch("https://pauloministro.com:8000/getimageuser/?event_id=" + event_id + "&search_number=" + user_number);
            //const data = await response.json();
            //if (data.error) {
            //console.error(data.error)
            ////setFetchError(data.error);
            //return "Algo de errado não está certo..."
            //}
            //let image_array = data.resized_images;

            //for (let image of image_array) {
            //resized_images.push(URL.createObjectURL(b64toBlob(image, 'image/jpeg')));
            //}
            //setCartItems([...cartItems, resized_images])
            //setAlreadyInCart(...alreadyInCart, chaves[0]);

            ////oldCartItems.push(resized_images);
            ////setCartItems(oldCartItems);

            //}))

            //for (let item of newCart) {

            //const chaves = Object.keys(item);
            //if (alreadyInCart.includes(chaves[0])) {
            //continue;
            //}

            //let [event_id, user_number, img_index] = chaves[0].split("_");

            //let resized_images = [];
            //const response = await fetch("https://pauloministro.com:8000/getimageuser/?event_id=" + event_id + "&search_number=" + user_number);
            //const data = await response.json();
            //if (data.error) {
            //console.error(data.error)
            ////setFetchError(data.error);
            //return "Algo de errado não está certo..."
            //}
            //let image_array = data.resized_images;

            //for (let image of image_array) {
            //resized_images.push(URL.createObjectURL(b64toBlob(image, 'image/jpeg')));
            //}
            //setCartItems([...cartItems, resized_images])
            //setAlreadyInCart([...alreadyInCart, chaves[0]]);

            ////oldCartItems.push(resized_images);
            ////setCartItems(oldCartItems);
            //}
            //console.log(newCart[3][3][1]["image_blob"])
            // TODO OPTMIZABLE

            //price

            // TODO SEE Promise.all AND SHIt

            const event_num = Object.keys(newCart)[0];
            const user_num = Object.keys(newCart[event_num])[0];
            const image_index = Object.keys(newCart[event_num][user_num])[0];

            if (typeof user_num === "string" && user_num.startsWith("0-")) {
                const resp = await fetch(
                    `https://pauloministro.com:8000/getimagezero/?event_id=${event_num}&search_number=${user_num}`
                );
                const data = await resp.json();
                if (data.error) {
                    console.error(data.error);
                    return "Algo de errado não está certo...";
                }
                let temp = [];
                for (let image of data.resized_images) {
                    temp.push(
                        URL.createObjectURL(b64toBlob(image, "image/jpeg"))
                    );
                }

                setResizedImages(temp);
                return;
            }

            let oldCart = newCart;
            for (let event_id of Object.keys(newCart)) {
                for (let user_number of Object.keys(newCart[event_id])) {
                    var resized_images = [];
                    const response = await fetch(
                        "https://pauloministro.com:8000/getimageuser/?event_id=" +
                            event_id +
                            "&search_number=" +
                            user_number
                    );
                    const data = await response.json();
                    if (data.error) {
                        console.error(data.error);
                        return "Algo de errado não está certo...";
                    }

                    let image_array = data.resized_images;

                    for (let image of image_array) {
                        resized_images.push(
                            URL.createObjectURL(b64toBlob(image, "image/jpeg"))
                        );
                    }
                    setResizedImages(resized_images);
                    //for (let index = 0; index < resized_images.length; index++) {
                    //let resized_image = resized_images[index];

                    //if (Object.keys(oldCart[event_id][user_number][index]).length === 0) {
                    //oldCart[event_id][user_number][index] = {"image_blob": resized_image};
                    //}

                    //oldCart[event_id][user_number][index]["image_blob"] = resized_image;
                    //try {
                    ////oldCart[event_id][user_number][index] = {...oldCart[event_id][user_number][index], "image_blob": resized_image};
                    //oldCart[event_id][user_number][index]["image_blob"] = resized_image;
                    //}
                    //catch (TypeError) {
                    //oldCart[event_id][user_number][index] = {"image_blob": resized_image};
                    //}

                    //try {
                    //oldCart[event_id][user_number][index]["images_blobs"].push(resized_image);
                    //}
                    //catch (TypeError) {
                    //oldCart[event_id][user_number][index]["images_blobs"] = [resized_image];
                    //}
                    //}
                }
            }
            //console.log(oldCart);
            setTeste(oldCart);
        };

        if (Object.keys(newCart).length > 0) {
            apiCall();
        }
        //}   //const apiCall = async () => {

        //}
    }, []);
    //console.log(teste);
    //const condition = typeof cartItems !== 'undefined' && cartItems.length > 0;
    //console.log(`type: ${typeof cartItems}, length: ${cartItems.length}, condition: ${condition}`);

    //useEffect(() =>
    //() => {
    //cartItems.forEach(images => images.forEach(imageUrl => URL.revokeObjectURL(imageUrl)));
    //}, []);

    //const makeCondition = (index) => (Number(index) === Number(img_index)) && imprim

    const FilledCart = () => {
        const isPoster =
            newCart[Object.keys(newCart)[0]][
                Object.keys(newCart[Object.keys(newCart)[0]])[0]
            ][
                Object.keys(
                    newCart[Object.keys(newCart)[0]][
                        Object.keys(newCart[Object.keys(newCart)[0]])[0]
                    ]
                )[0]
            ].imprim;

        const selectedImage =
            Object.keys(newCart)[0] &&
            Object.keys(newCart[Object.keys(newCart)[0]])[0] &&
            Object.keys(
                newCart[Object.keys(newCart)[0]][
                    Object.keys(newCart[Object.keys(newCart)[0]])[0]
                ]
            )[0];

        const updatePrice = (poster) => {
            const user_number = Object.keys(
                newCart[Object.keys(newCart)[0]]
            )[0];

            let priceToAdd = 0;
            if (user_number == 0) {
                priceToAdd = newCart.precoIndividualPoster;
            } else {
                priceToAdd = newCart.precoPoster;
            }

            if (poster) {
                newCart.totalPrice += priceToAdd;
            } else {
                newCart.totalPrice -= priceToAdd;
            }
            setNewCart({
                ...newCart,
                totalPrice: newCart.totalPrice,
            });
        };

        const updatePoster = (newValue, image_idx) => {
            let fakeNewCart = { ...newCart };
            fakeNewCart[Object.keys(fakeNewCart)[0]][
                Object.keys(fakeNewCart[Object.keys(fakeNewCart)[0]])[0]
            ][
                Object.keys(
                    fakeNewCart[Object.keys(fakeNewCart)[0]][
                        Object.keys(fakeNewCart[Object.keys(fakeNewCart)[0]])[0]
                    ]
                )[0]
            ].imprim = newValue;
            const clone = structuredClone(fakeNewCart);
            setNewCart(clone);
            updatePrice(newValue);
        };

        const [checkboxStates, setCheckboxStates] = useState([]);
        const handleCheckboxChange = (index) => (event) => {
            const newCheckboxStates = checkboxStates.map((state, i) =>
                i == index ? event.target.checked : state
            ); //state
            setCheckboxStates(newCheckboxStates);
        };

        useEffect(() => {
            const initialCheckboxStates = residedImages.map(
                (_, index) => isPoster && selectedImage == index
            );
            setCheckboxStates(initialCheckboxStates);
        }, [selectedImage, isPoster]);

        const [selItem, setSelItem] = useState(
            isPoster ? Number(selectedImage) : null
        );
        const handleChange = (item) => {
            item === selItem ? setSelItem(null) : setSelItem(item);
            const user_number = Object.keys(
                newCart[Object.keys(newCart)[0]]
            )[0];

            let priceToAdd = 0;
            if (user_number == 0) {
                priceToAdd = newCart.precoIndividualPoster;
            } else {
                priceToAdd = newCart.precoPoster;
            }

            // subtract prive if item is already selected
            if (item === selItem) {
                setBuyButtonId(buyDigital);
                newCart.totalPrice -= priceToAdd;
            }
            // add price if item is not selected
            else {
                setBuyButtonId(buyPoster);
                if (selItem !== null) {
                    return;
                }
                newCart.totalPrice += priceToAdd;
            }
        };

        const event = Object.keys(newCart)[0];
        const user = Object.keys(newCart[Object.keys(newCart)[0]])[0];
        const image = selItem;

        const client_reference = `${event}_${user}_${image}`;

        const [buyButtonId, setBuyButtonId] = useState(
            isPoster ? "poster" : "digital"
        );

        const publishable_key =
            "pk_live_51KEHfhLIV9OlqsbklnPnRH5r77rn3U4sF8mDG0TabBxkM6YA9w5mbsV224sZkm9Rd6jGhZRMK2ChAWYh0SNzT5Gh002t9ghMIG";

        const DigitalButton = () => (
            <stripe-buy-button
                buy-button-id="buy_btn_1NGA74LIV9OlqsbkNDrSm2wl"
                publishable-key={publishable_key}
                client-reference-id={client_reference}
                className={classes.checkoutButton}
            ></stripe-buy-button>
        );

        const PosterButton = () => (
            <stripe-buy-button
                buy-button-id="buy_btn_1NGrCULIV9OlqsbkcwKCi0YP"
                publishable-key={publishable_key}
                client-reference-id={client_reference}
                className={classes.checkoutButton}
            ></stripe-buy-button>
        );

        return (
            <>
                {/* <Grid container spacing={3}>
                {Object.keys(teste).map((item) => (
                    <>
                    {item.map((imageblob) => (
                        <Grid item xs={12} sm={4} key={item.id}>
                            <CartItem item={item} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} imageBlob={teste["1"]["images_blobs"]["0"]}  />
                        </Grid>
                    ))}
                    </>
                ))}
            </Grid> */}
                {/*
            <Grid container spacing={3}>
                {Object.entries(teste).map(([event_id, users], event_index) => (
                    <>
                        {Object.entries(users).map(([user_number, images], user_index) => (
                            <>
                                {Object.entries(images).map(([img_number, image_data], img_index) => (
                                    <Grid item xs={12} sm={4} key={event_id + "_" + user_number + "_" + img_number}>
                                        <NewCartItem img_index={img_index} event_id={event_id} user_number={user_number} image_number={img_number} image_data={image_data}  onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart}  />
                                    </Grid>
                                ))}
                            </>
                        ))}
                    </>
                ))}
            </Grid>
            */}
                <Grid container spacing={3}>
                    {residedImages.map((image, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            {/* makeCondition(index) && (console.log(`index: ${index}, img_index: ${img_index}, imprim: ${imprim}, logical: ${makeCondition(index)}`)) */}
                            <NewCartItem
                                image={image}
                                image_index={index}
                                //total={total}
                                //setTotal={setTotal}
                                digital={true}
                                external={null}
                                price={{
                                    precoDigital: newCart.precoDigital,
                                    precoPoster: newCart.precoPoster,
                                    precoIndividualDigital:
                                        newCart.precoIndividualDigital,
                                    precoIndividualPoster:
                                        newCart.precoIndividualPoster,
                                    individual: newCart.individual,
                                }}
                                isPoster={isPoster}
                                updatePoster={updatePoster}
                                selectedImage={selectedImage}
                                handleCheckboxChange={handleCheckboxChange}
                                checkboxStates={checkboxStates}
                                handleChange={handleChange}
                                selItem={selItem}
                            />
                        </Grid>
                    ))}
                </Grid>

                <div className={classes.cardDetails}>
                    <Typography variant="h4">
                        {/* Total: {cart.subtotal.formatted_with_symbol} */}
                        Total: {newCart.totalPrice}€
                    </Typography>
                    <div className={classes.buttons}>
                        <Button
                            className={(classes.emptyButton, classes.button)}
                            size="large"
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                                EmptyNewCart(
                                    newCart,
                                    residedImages,
                                    setResizedImages,
                                    setCartItems,
                                    setCartIndex
                                )
                            }
                        >
                            Esvaziar o carrinho
                        </Button>
                        {/* <Button
                            component={Link}
                            to="/checkout"
                            className={classes.checkoutButton}
                            size="large"
                            type="button"
                            variant="contained"
                            color="primary"
                        >
                            Checkout
                        </Button> */}
                        <div className={classes.checkoutButton}>
                            {buyButtonId === "poster" ? (
                                <PosterButton />
                            ) : (
                                <DigitalButton />
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    };
    //if (condition) {
    //console.log("NOT EMPTY")
    //}
    //console.log(`content: ${teste[74]}\nvalue content: ${Object.values(teste)[0]}\nvalues length: ${Object.values(teste).length}\nlength: ${teste.length}`)

    return (
        <>
            <Container setCartItems={setCartItems}>
                <div className={classes.toolbar} />
                <Typography variant="h3" className={classes.title} gutterBottom>
                    O seu carrinho de compras
                </Typography>
                {/*{ !Object.values(teste).length ? <EmptyCart /> : <FilledCart />} */}
                {!residedImages.length ? <EmptyCart /> : <FilledCart />}
            </Container>
        </>
    );
};

export default Cart;
