import React from "react";

import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    IconButton,
    Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./styles";

const Event = ({ image, images, eventInfo }) => {
    const classes = useStyles();

    return (
        <div style={{ maxWidth: "100%" }}>
            <Card className={classes.root}>
                <CardMedia className={classes.media} image={image} />
                <CardContent>
                    <div className={classes.cardContent}>
                        <Typography gutterBottom variant="h6" component="h2">
                            {eventInfo.name.replace(/_/g, " ")}
                        </Typography>
                    </div>
                    <Typography
                        style={{ align: "left" }}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {eventInfo.local}, {eventInfo.date}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                    <Link to={"/evento/" + eventInfo.id}>
                        <Button style={{ color: "#1788da" }}>
                            Mostrar Fotos
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
    );
};

export default Event;
