import { Typography } from "@material-ui/core";

import "./styles.css";

const Termos = () => {
    return (
        <ol>
            <li className="item-termos">
                <Typography variant="h2">
                    <strong>Uso Deste Website</strong>
                </Typography>

                <Typography variant="h5">
                    Este website deve ser utilizado unicamente para fins
                    pessoais.
                    <br /> É proibida a sua modificação, reprodução,
                    distribuição, venda, ou qualquer outra forma de divulgação
                    ou transmissão com fins lucrativos ou comerciais.
                    <br /> Salvo indicação expressa em contrário, Paulo Ministro
                    é o titular dos direitos de propriedade intelectual sobre o
                    site e os seus conteúdos.
                    <br /> O utilizador obriga-se a não utilizar este website
                    para fins ilícitos ou proibidos.
                </Typography>
            </li>

            <li className="item-termos">
                <Typography variant="h2">
                    <strong>Direitos de Autor</strong>
                </Typography>

                <Typography variant="h5">
                    O texto, gráficos, imagens, ficheiros de som e ficheiros de
                    animação ou vídeo existentes neste site estão sujeitos às
                    disposições legais aplicáveis à protecção de direitos de
                    autor e de propriedade intelectual.
                    <br />O conteúdo deste website não pode ser copiado para uso
                    comercial ou para distribuição, nem ser modificado ou
                    reenviado para outros websites.
                </Typography>
            </li>
            <li className="item-termos">
                <Typography variant="h2">
                    <strong>Política de Devoluções e Reembolso</strong>
                </Typography>
                <Typography variant="h5">
                    Se adquiriu fotografias, em formato digital ou impressas, no
                    site <a href="/">pauloministro.com</a> e não está
                    satisfeita/o, pode pedir até três retificações para
                    conseguir o especto desejado.
                    <br /> Caso não tenha atingido, ao fim de três retificações,
                    o efeito desejado, poderá pedir a devolução de 100% do valor
                    pago.
                </Typography>
                <Typography variant="h5">
                    Poderá fazê-lo da seguinte forma:
                </Typography>

                <ol className="lista-reembolso">
                    <li>
                        <ol>
                            <Typography variant="h5">
                                Contactar-nos através do nº +351917741820 ou
                                email pauloministro2007@gmail.com para
                                informações sobre procedimentos de devolução.
                                <br /> As condições da devolução são as
                                seguintes:
                            </Typography>
                            <li style={{ marginLeft: "2rem" }}>
                                <Typography variant="h5">
                                    As fotografias foram retificadas até 3
                                    vezes, sem sucesso.
                                </Typography>
                            </li>
                        </ol>
                    </li>
                </ol>
                <Typography variant="h5">
                    <strong>Devoluções por danos de transporte</strong>
                </Typography>

                <Typography variant="h5">
                    Caso detete alguma anomalia ou dano causado pelo transporte
                    do artigo que encomendou, será enviado outro ou devolveremos
                    100% do valor pago.
                </Typography>

                <Typography variant="h5">
                    <strong>Reembolso</strong>
                </Typography>

                <Typography variant="h5">
                    No caso de devolução, o reembolso do valor da sua compra
                    será efetuado após a validação do cumprimento das condições
                    de devolução descritos anteriormente, tendo um prazo de até
                    30 dias para o efeito a partir do momento em que recebemos
                    as fotos por correio.
                </Typography>
            </li>

            <li className="item-termos">
                <Typography variant="h2">
                    <strong>Encomenda e Pagamento</strong>
                </Typography>

                <ol className="lista-pagamentos">
                    <li>
                        <Typography variant="h5">
                            Para realizar uma compra na Loja Online de Paulo
                            Ministro, o utilizador deverá escolher o evento e se
                            identificar para compras as suas fotos.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            Após a confrimação da encomenda deverá selecionar o
                            método de pagamento que pretender, entre as opções
                            disponíveis.
                            <br /> Qualquer encomenda efetuada na loja online de
                            Paulo Ministro só será processada após o recebimento
                            do efetivo pagamento.
                            <br />A formalização da encomenda só em si não
                            contitui uma reserva dos produtos.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            Após a confirmação da encomenda na loja online de
                            Paulo Ministro, será enviado um e-mail para o
                            endereço de contacto registado na mesma pelo cliente
                            aquando da formalização, com as fotos escolhidas.
                            <br /> Caso o cliente não receba o e-mail de
                            confirmação de encomenda é solicitado que entre em
                            contacto com Paulo MInistro através do contacto
                            telefónico +351917741820 ou através do email
                            pauloministro2007@gmail.com.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            As alterações das condições das encomendas não
                            expedidas ou a anulação de uma encomenda não
                            expedida podem ser realizadas quando solicitadas
                            através do número de telemóvel +351917741820
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            As encomendas realizadas por utilizadores de países
                            fora da União Europeia têm de ser previamente
                            validadas.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            As encomendas só serão enviadas após pagamento e
                            serão processadas por ordem de chegada.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h5">
                            Caso uma encomenda não possa ser satisfeita em parte
                            ou na totalidade, o cliente será imediatamente
                            contactado por Paulo Ministro.
                        </Typography>
                    </li>
                </ol>
            </li>
        </ol>
    );
};

export default Termos;
