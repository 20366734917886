import React, { useState, useEffect } from "react";
import {
    Paper,
    Stepper,
    Step,
    StepLabel,
    Typography,
    CircularProgress,
    Divider,
    Button,
    CssBaseline,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";

import { commerce } from "../../../lib/commerce";
import useStyles from "./styles";
import AddressForm from "../AddressForm";
import PaymentForm from "../PaymentForm";

const steps = [
    "Método de pagamento",
    "Endereço de envio",
    "Detalhes do pagamento",
];

const Checkout = ({ cart, order, onCaptureCheckout, error }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [checkoutToken, setCheckoutToken] = useState(null);
    const [shippingData, setShippingData] = useState({});
    const [isFinished, setIsFinished] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const nextStep = () =>
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const backStep = () =>
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    useEffect(() => {
        const genereteToken = async () => {
            try {
                const token = await commerce.checkout.generateToken(cart.id, {
                    type: "cart",
                });

                setCheckoutToken(token);
            } catch (error) {
                //navigate("/")
            }
        };
        genereteToken();
    }, [cart]);

    const next = (data) => {
        //console.log(data)
        setShippingData(data);
        nextStep();
    };

    const timeout = () => {
        setTimeout(() => {
            setIsFinished(true);
        }, 3000);
    };

    let Confirmation = () =>
        order.customer ? (
            <>
                <div>
                    <Typography variant="h5">
                        Obrigado por sua compra, {order.customer.firstname}{" "}
                        {order.customer.lastname}!
                    </Typography>
                    <Divider className={classes.Divider} />
                    <Typography variant="subtitle2">
                        Referência do pedido: {order.customer_reference}
                    </Typography>
                </div>
                <br />
                <Button
                    component={Link}
                    to="/"
                    variant="outlined"
                    type="button"
                >
                    Voltar para o menu principal
                </Button>
            </>
        ) : isFinished ? ( //TODO remover na prod
            <>
                <div>
                    <Typography variant="h5">
                        Obrigado por sua compra!
                    </Typography>
                    <Divider className={classes.Divider} />
                    <Typography variant="subtitle2">
                        Referência do pedido:{" "}
                    </Typography>
                </div>
                <br />
                <Button
                    component={Link}
                    to="/"
                    variant="outlined"
                    type="button"
                >
                    Voltar para o menu principal
                </Button>
            </>
        ) : (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );

    //if(error) {
    //<>
    //<Typography variant="h5">Ocorreu um erro ao processar o pedido<br/>Erro: {error}</Typography>
    //<br />
    //<Button component={Link} to="/" variant="outlined" type="button" >Voltar para o menu principal</Button>
    //</>
    //}

    const Form = () =>
        activeStep === 0 ? (
            <AddressForm checkoutToken={checkoutToken} next={next} />
        ) : (
            <PaymentForm
                shippingData={shippingData}
                checkoutToken={checkoutToken}
                backStep={backStep}
                onCaptureCheckout={onCaptureCheckout}
                nextStep={nextStep}
                timeout={timeout}
            />
        );

    return (
        <>
            <CssBaseline />
            <div className={classes.toolbar} />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant="h4" align="center">
                        Checkout
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        className={classes.stepper}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? <Confirmation /> : <Form />}
                </Paper>
            </main>
        </>
    );
};

export default Checkout;
