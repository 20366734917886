import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { commerce } from '../../lib/commerce';

import FormInput from './CustomTextField';

//const fs = require('fs')



const AddressForm = ({ checkoutToken, next }) => {
    const [shippingCountries, setShippingCountries] = useState([]);
    const [shippingCountry, setShippingCountry] = useState('');
    const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
    const [shippingSubdivision, setShippingSubdivision] = useState('');
    const [shippingOptions, setShippingOptions] = useState([]);
    const [shippingOption, setShippingOption] = useState('');

    const methods = useForm();


    //const countries = Object.entries(shippingCountries).map(([code, name]) => ({ id: code, label: name }));
    //const subdivisions = Object.entries(shippingSubdivisions).map(([code, name]) => ({ id: code, label: name }));
    //const options = shippingOptions.map((sO) => ({ id: sO.id, label: `${sO.description} - (${sO.price.formatted_with_symbol})` }));



    ////setting countries
    //const fetchShippingCountries = async (checkoutTokenId) => {
        //let isMounted = true;

        //const { countries } = await commerce.services.localeListShippingCountries(checkoutTokenId);
        //if (isMounted) {
            //setShippingCountries(countries);
            //setShippingCountry("PT");
        //}
        //return () => isMounted = false;        
        //// for (const code of Object.keys(countries)) {
        ////     if (code === 'PT') {
        ////         setShippingCountry(code);
        ////         break;
        ////     }
        //// }
        //// setShippingCountry(Object.keys(countries).map((code) => code === "PT" ? code : Object.keys(countries)[0]));
        //// setShippingCountry(countries.PT);
    //}

    ////setting subdivisions
    //const fetchShippingSubdivisions = async (countryCode) => {
        //const { subdivisions } = await commerce.services.localeListSubdivisions(countryCode);

        
        //setShippingSubdivisions(subdivisions);
        
        //setShippingSubdivision(Object.keys(subdivisions)[0]);
    //}

    //const fetchShippingOptions = async (checkoutTokenId, country, region = null) => {
        //const shippingOptions  = await commerce.checkout.getShippingOptions(checkoutTokenId, { country, region });

        //setShippingOptions(shippingOptions);
        //setShippingOption(shippingOptions[0].id);
    //}

    ////get countries
    //useEffect(() => {
        //fetchShippingCountries(checkoutToken.id);
    //}, []);

    ////get subdivisions
    //useEffect(() => {
        //if (shippingCountry) {
            //fetchShippingSubdivisions(shippingCountry);
        //}
        
    //}, [shippingCountry]);

    ////get shipping options
    //useEffect(() => {
        //if (shippingSubdivision) {
            //fetchShippingOptions(checkoutToken.id, shippingCountry, shippingSubdivision);
        //}
    //}, [shippingSubdivision]);


    //const salvar = async (data, shippingCountry, shippingSubdivision, shippingOption) => {


        //await fetch("http://localhost:5000/data", {
     
            //// Adding method type
            //method: "POST",
            //mode: "cors",
            //// Adding body or contents to send
            //body: JSON.stringify({
                //data: "foo",
                
            //}),
             
            //// Adding headers to the request
            //headers: {
                //"Content-type": "application/json; charset=UTF-8"
            //}
        //})
         
        //// Converting to JSON
        //.then(response => response)
         
        //// Displaying results to console
        //.then(json => console.log(json));



        //// await fetch("http://localhost:5000/data", {
        ////     mode: 'cors',
        ////     method: "POST",
        ////     body: JSON.stringify({data: "comi a tua mae"}),
        ////     headers: {
        ////         "Content-type": "application/json; charset=UTF-8"
        ////     },
            
        //// }).then(res => res).then(data => console.log(data)).catch(err => console.error(err));
        
        
        //console.log("DEU");
    

        //// fs.writeFile("./data.txt", data, err => {
        ////     if (err) {
        ////         console.log(err);
        ////         return;
        ////     }
        //// })
        //// next(...data, shippingCountry, shippingSubdivision, shippingOption);
    //}





    return (
        <>
            <Typography variant="h6" gutterBottom>Endereço de envio</Typography>
            <FormProvider {...methods}>
              {/* <form onSubmit={(data) => console.log(data)}> */}

              <form onSubmit={methods.handleSubmit((data) => next({...data}))}>
                    <Grid container spacing={3}>
                        <FormInput name="firstName" label="Nome" />
                        <FormInput name="lastName" label="Subrenome" />
                        <FormInput name="address1" label="Endereço" />
                        <FormInput name="email" label="Email" />
                        <FormInput name="city" label="Cidade" />
                        <FormInput name="zip" label="Codigo postal" />
                        {/*<Grid item xs={12} sm={6} >
                            <InputLabel>País de envio</InputLabel>
                            <Select value={shippingCountry} fullWidth onChange={(e) => setShippingCountry(e.target.value)}>
                                {countries.map(({id, label}) => (
                                    <MenuItem key={id} value={id ?? ""}>
                                        {!label ? "Portugal" : label}
                                    </MenuItem>
                                ))}
                            </Select>
                                </Grid> */}
                        {/*<Grid item xs={12} sm={6} >
                            <InputLabel>Regiões de envio</InputLabel>
                            <Select value={shippingSubdivision} fullWidth onChange={(e) => setShippingSubdivision(e.target.value)}>
                                {subdivisions.map(({id, label}) => (
                                    <MenuItem key={id} value={id ?? ""}>
                                        {label} optimize needed*
                                    </MenuItem>
                                ))}
                                
                            </Select>
                                </Grid>*/}
                        {/*<Grid item xs={12} sm={6} >
                            <InputLabel>Opções de envio</InputLabel>
                            <Select value={shippingOption} fullWidth onChange={(e) => setShippingOption(e.target.value)}>
                                {options.map(({id, label}) => (
                                    <MenuItem key={id} value={id ?? ""}>
                                        {label}
                                    </MenuItem>
                                ))}
                                
                            </Select>
                        </Grid>
                                */}
                                </Grid>
                <br />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button component={Link} to="/carrinho" variant="outlined" >De volta ao carrinho</Button>
                    <Button variant="contained" type="submit" color="primary" >Proximo</Button>
                </div>
                </form>
            </FormProvider>
        </>
    );
};

export default AddressForm;
