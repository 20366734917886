import useStyles from './styles'

import { Link } from 'react-router-dom';
import { Container, Typography, Button, Grid } from '@material-ui/core'
export const EmptyCart = () => {

    const classes = useStyles();
    return (
        <Typography variant="subtitle1">
            O seu carrinho não tem itens.{"\n"}
            <Link to="/" className={classes.link}>
                Comece adicionando alguns!
            </Link>
        </Typography>
    )
}

