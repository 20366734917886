import React, { useState } from "react";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    IconButton,
    Button,
    Box,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";

import useStyles from "./styles";

import Clicked from "./Clicked";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Link, useNavigate } from "react-router-dom";

//import CustomPopup from "./CartPopup/CartPopup";

import "./estilos.css";

const Open = ({ image, name }) => {
    return (
        <center>
            <img src={image} alt={name} />
        </center>
    );
};

const Product = ({
    user_number,
    event_id,
    newOnAddToCart,
    product,
    onAddToCart,
    image,
    Index,
    resizedImages,
    eventPrices,
}) => {
    var index = Index;
    let navigate = useNavigate();
    if (index === undefined) index = 0;
    const [isZoom, setIsZoom] = useState({ index: index, isOpen: false });
    const classes = useStyles();
    const [ApiImage, setApiImage] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [cartIndex, setCartIndex] = useState(index);
    const [newIndex, setNewIndex] = useState(index);

    const [visibility, setVisibility] = useState(false);

    const popupCloseHandler = (e) => {
        newOnAddToCart(
            user_number,
            event_id,
            checked[1],
            checked[0],
            1,
            cartIndex,
            user_number,
            eventPrices.precoDigital,
            eventPrices.precoPoster,
            eventPrices.precoIndividualDigital,
            eventPrices.precoIndividualPoster
        );
        handleClose();
        //setVisibility(e);
        setIsZoom({ index: cartIndex, isOpen: true });
    };

    const popupOpenHandler = (e) => {
        //setVisibility(e);
        //console.log(index, index.length)
        handleOpen();
        setIsZoom({ index: resizedImages.length, isOpen: false });
    };

    const [checked, setChecked] = useState([true, false]);
    const handleChangeDigital = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };
    const handleChangePhysical = (event) => {
        setChecked([checked[0], event.target.checked]);
    };
    const handleChangeAll = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    const children = (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <FormControlLabel
                label={`Comprar pack digital (todas as fotos) - ${
                    user_number > 0
                        ? eventPrices.precoDigital
                        : eventPrices.precoIndividualDigital
                }€`}
                control={
                    <Checkbox
                        //checked={checked[0]}
                        checked={true}
                        onChange={handleChangeDigital}
                        disabled={true}
                    />
                }
            />
            <FormControlLabel
                label={`Comprar poster (33cm x 66cm) + ${
                    user_number > 0
                        ? eventPrices.precoPoster
                        : eventPrices.precoIndividualPoster
                }€`}
                control={
                    <Checkbox
                        checked={checked[1]}
                        onChange={handleChangePhysical}
                    />
                }
            />
        </Box>
    );

    const handleContinue = () => {
        setOpen(false);
        //console.log(isZoom.index);
        newOnAddToCart(
            user_number,
            event_id,
            checked[1],
            checked[0],
            1,
            cartIndex,
            user_number,
            eventPrices.precoDigital,
            eventPrices.precoPoster,
            eventPrices.precoIndividualDigital,
            eventPrices.precoIndividualPoster
        );
        setIsZoom({ index: cartIndex, isOpen: true });
    };
    const handleCartNew = () => {
        //console.log("Digital: " + checked[0] + " Físico: " + checked[1]);
        newOnAddToCart(
            user_number,
            event_id,
            checked[1],
            checked[0],
            1,
            cartIndex,
            user_number,
            eventPrices.precoDigital,
            eventPrices.precoPoster,
            eventPrices.precoIndividualDigital,
            eventPrices.precoIndividualPoster
        );
        navigate("/carrinho");
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 15,
    };
    // console.log(product);
    // return <div>teste</div>

    //const images = Object.values(product.image.url)

    //const helperPlus = (index) => {
    ////console.log(index)
    //const pimba = index + 1;
    ////const pimba = index + 2;
    //console.log(pimba)
    //return pimba;

    //}
    //const helperLess = (index) => {
    ////console.log(index)
    ////const pimba = (index + 1) % images.length;
    //const pimba = (index + images.length - 1) % images.length;
    //console.log(pimba)
    //return pimba;
    //}
    //const randomImage = (images) => {
    ////const min = Math.ceil(1);
    ////const max = Math.floor(images.length);
    ////const index = Math.floor(Math.ranroundrounddom() * (max - min) - min);
    //const index = Math.floor(Math.random() * (images.length - 1 + 1)) + 1;
    //return index;
    //}

    return (
        // <p>comi a tua mae aquela badalhoca</p>
        //<div>
        //<Button className={classes.button} onClick={() => console.log(isZoom.index)}>
        //<img src={image} alt={product.name} className={classes.images}/>

        //</Button>

        //</div>

        //TODO IMPORTANTE COISA DE setIsZoom no onclick

        <div>
            <Button
                className={classes.button}
                onClick={() => setIsZoom({ index: index, isOpen: true })}
            >
                <img src={image} alt={"Imagem"} className={classes.images} />
            </Button>

            {isZoom.isOpen && (
                <Lightbox
                    enableZoom={false}
                    //discourageDownloads={true}
                    clickOutsideToClose={true}
                    animationOnKeyInput={true}
                    animationDisabled={false}
                    mainSrc={resizedImages[isZoom.index]}
                    nextSrc={
                        resizedImages[(isZoom.index + 1) % resizedImages.length]
                    }
                    prevSrc={
                        resizedImages[
                            (isZoom.index + resizedImages.length - 1) %
                                resizedImages.length
                        ]
                    }
                    onCloseRequest={() =>
                        setIsZoom({ index: isZoom.index, isOpen: false })
                    }
                    onMovePrevRequest={() => {
                        //helperLess(index)
                        const newIndex =
                            (isZoom.index + resizedImages.length - 1) %
                            resizedImages.length;
                        setIsZoom({ index: newIndex, isOpen: true });
                        setCartIndex(newIndex);
                        //index = (index + resizedImages.length - 1) % resizedImages.length
                    }}
                    onMoveNextRequest={() => {
                        //helperPlus(index)
                        const newIndex =
                            (isZoom.index + 1) % resizedImages.length;
                        setIsZoom({ index: newIndex, isOpen: true });
                        setCartIndex(newIndex);
                        //index = (index + 1) % resizedImages.length
                    }}
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    /* TODO IMPORTANTE VERIFICAR FOTOS DO PAI GRNADES*/
                    //imageCaption={<Button type="button" variant="contained" color="secondary" className={classes.centro} size="large" >Comprar 69€</Button>}
                    imageCaption={
                        <Button
                            onClick={popupOpenHandler}
                            variant="contained"
                            color="secondary"
                            size="large"
                        >
                            Adicionar ao Carrinho
                        </Button>
                    }
                    //toolbarButtons={
                    //[<Button key="1" onClick={() => console.log("etste")}  type="button" variant="contained" color="secondary" size="large" >Teste Fetch</Button>]
                    //}
                    className="modal_image"
                />
            )}

            <Modal
                open={open}
                onClose={popupCloseHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        center
                    >
                        Adicionado ao Carrinho
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {/*<FormControlLabel
                            label={`Todos -> ${
                                eventPrices.precoDigital +
                                eventPrices.precoPoster
                            }€`}
                            control={
                                <Checkbox
                                    checked={checked[0] && checked[1]}
                                    indeterminate={checked[0] !== checked[1]}
                                    onChange={handleChangeAll}
                                />
                            }
                        /> */}
                        {children}
                    </Typography>
                    <center>
                        <Button
                            onClick={handleCartNew}
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#1788da",
                                color: "white",
                                width: "100%",
                            }}
                        >
                            Ir para o Carrinho
                        </Button>
                        <br />
                        <Button
                            onClick={handleContinue}
                            style={{
                                marginTop: "10px",
                                color: "#7a848b",
                                fontSize: "12px",
                            }}
                        >
                            Continuar a comprar
                        </Button>
                    </center>
                </Box>
            </Modal>
        </div>

        //<CustomPopup
        //onClose={popupCloseHandler}
        //show={visibility}
        //title="Hello Jeetendra"
        //>
        //<h1>Hello This is Popup Content Area</h1>
        //<h2>This is my lorem ipsum text here!</h2>
        //</CustomPopup>
    );
};

export default Product;
