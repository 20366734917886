import { Typography } from "@material-ui/core";
import "./styles.css";

const Contacto = () => {
    return (
        <div>
            <Typography variant="h2" style={{ paddingBottom: "1rem" }}>
                <strong>Dados da Empresa</strong>
            </Typography>

            <ul style={{ marginLeft: "0.5rem" }}>
                <li>
                    <Typography variant="h5">
                        <p>Rua José Lopes da Silva Granja N.º67 - 1º Direito</p>
                        <p>4705-225 Lomar Braga</p>
                    </Typography>
                </li>
                <br />
                <li>
                    <Typography variant="h5">
                        <strong>Telemóvel:</strong>
                        <a className="phone-link" href="tel:+351 917 741 820">
                            +351 917 741 820
                        </a>
                    </Typography>
                </li>
                <br />
                <li>
                    <Typography variant="h5">
                        <strong>Email:</strong>
                        <a
                            className="email-link"
                            href="mailto:pauloministro2007@gmail.com"
                        >
                            pauloministro2007@gmail.com
                        </a>
                    </Typography>
                    <Typography variant="h5"></Typography>
                </li>
                <li>
                    <Typography variant="h5">
                        <strong>Contribuinte: </strong>
                        202541258
                    </Typography>
                </li>
            </ul>
        </div>
    );
};

export default Contacto;
